import { ContentTypeApps, ContentTypeModels } from './constants';
import { User } from './user.model';
import { Auditable, PartialEntity } from './utils.model';

export enum CourseStatus {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  REJECTED = 'rejected',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export type CourseV2 = Auditable & {
  id: string;
  project: string;
  version: string;
  code?: any;
  user: any;
  title: string;
  slug?: string;
  type?: string;
  currency?: number;
  currency_code?: string;
  pricing?: number;
  subtitle?: string;
  desc?: string;
  description?: DescriptionV2;
  video_info?: VideoInfoV2;
  is_promote?: boolean;
  skill_level?: string;
  category?: string;
  subcategory?: number | string;
  language?: string;
  topics?: number[];
  primary_topic?: string;
  status?: CourseStatus;
  begin_msg?: string;
  completion_msg?: string;
  price?: string;
  included_in_promo?: boolean;
  date_published?: string;
  is_deleted?: boolean;
  image?: string;
  promo_video?: string;
  source?: CourseV2 | any; // TODO: Fix this type of source
  owned?: boolean;

  // Computed properties
  image_url?: string;
  promo_video_url?: string;
  unpublished_project?: Project[];
  progress?: number | null;
  course_sold?: number;
  average_rating?: number;
  total_reviews?: number;
  course_type?: string;
  course_curriculum?: CourseCurriculum;
  course_id?: string;
  total_rate?: number;
  favorite?: boolean;
  priceDetails?: PriceDetails;
  chain_id?: string;
  currency_snapshot?: string;
  price_snapshot?: string;
  category_name?: string;
  favoritized?: number;
  currency_preview?: number;
};

interface PriceDetails {
  price_serve_tracking_id: string;
  list_price: {
    amount: number;
    currency: string;
    price_string: string;
  };
  price: {
    amount: number;
    currency: string;
    price_string: string;
  };
  discount_price: {
    discount_amount: number;
    discount_percentage: number;
    currency: string;
    price_string: string;
    has_discount: false;
  };
  price_detail: {
    amount: number;
    currency: string;
    price_string: string;
  };
}

interface CourseCurriculum {
  total_sections: number;
  total_lectures: number;
}

export type DescriptionV2 = {};
export type VideoInfoV2 = {
  size?: string;
  error?: string;
  event?: string;
  status?: string;
  duration?: number;
  course_id?: string;
  task_token?: string;
  library?: string;
};

export type SectionV2 = Auditable & {
  id: string;
  course: string;
  title: string;
  position?: number;
  duration?: string;
  source?: SectionV2 | any;
  // source?: SectionV2;
  // TODO JHJ remove?
  // subsections?: SubsectionV2[];
};

export enum SubsectionType {
  LECTURE = 'lecture',
  QUIZ = 'quiz',
  ASSIGNMENT = 'assignment',
}

export type SubsectionV2 = Auditable & {
  id: string;
  type: SubsectionType;
  position?: number;
  quiz?: string;
  lecture?: string;
  assignment?: string;
  section: string;
  source?: SubsectionV2;
};

export type LectureType = 'video' | 'article';

export type LectureV2 = Auditable & {
  id: string;
  title: string;
  video?: string;
  video_id?: string;
  video_info?: StreamVideoInfoV2;
  article?: string;
  type?: LectureType;
  position?: number;
  preview?: boolean;
  published?: boolean;
  section: string;
  source?: LectureV2 | any; // TODO: Fix this type of source

  // computed properties
  /** presigned url from backend */
  video_url?: string;
  chain_id?: string;
  resources?: PartialEntity<LectureResourceV2>[];
};
export type LectureResourceV2 = Auditable & {
  id: string;
  file?: string;
  lecture: string;
};
export type StreamVideoInfoV2 = {
  uid?: string;
  thumbnail?: string;
  playback?: { url: string };

  name?: string;
  size: string;
  type?: string;
  user?: string;
  error?: string;
  event?: string;
  course?: string;
  public?: string;
  status: string;
  lecture?: string;
  library?: string;
  duration: number;
  course_id?: string;
  lecture_id?: string;
  task_token?: string;
  content_type?: string;
};

export type QuizV2 = Auditable & {
  id: string;
  title: string;
  section: string;
};
export type QuizQuestionV2 = Auditable & {
  id: string;
  question: string;
  lecture?: string;
  quiz: string;
};
export type QuizAnswerV2 = Auditable & {
  id: string;
  answer: string;
  is_correct?: boolean;
  expected?: string;
  quiz_question: string;
};

export type AssignmentV2 = Auditable & {
  id: string;
  title: string;
  // in DB but not in UI
  // lecture?: string;
  section: string;
  source?: SectionV2 | string;
};
export type AssignmentQuestionV2 = Auditable & {
  id: string;
  question: string;
  expected?: string;
  assignment: string;
  source?: SectionV2 | string;
};

export type IntendedLearnerTypes = 'learners' | 'objectives' | 'requirements';

export type LearnerV2 = Auditable & {
  id: string;
  content: string;
  course: string;
};
export type RequirementV2 = Auditable & {
  id: string;
  content: string;
  course: string;
};
export type ObjectiveV2 = Auditable & {
  id: string;
  content: string;
  course: string;
};

export interface CategoryV2 {
  id: string;
  name: string;
  slug: string;
  icon: string;
  image?: string;
  subcategories?: SubCategoryV2[];
  type?: 'category' | 'subcategory';
  parent?: CategoryV2;
  description?: string;
}

export interface SubCategoryV2 {
  id: string;
  category: string;
  name: string;
  image?: string;
  slug: string;
  related_topics?: TopicV2[];
  description?: string;
}

export interface FilterOption {
  value: string;
  label: string;
}

export interface TopicV2 {
  name: string;
  id: string;
  slug: string;
  courses_count?: number;
  courses_used?: number;
  description?: string;
}
export interface TopicCreateV2 {
  id: string;
  name: string;
}

export interface Feedback {
  count?: boolean | number;
  updated?: {
    by: string;
    date?: string;
  };
}

export type CourseResponse = {
  next: string | null;
  previous: string | null;
  count: number;
  results: Partial<CourseV2[]>;
};

export enum ProjectState {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  PUBLISHED = 'published',
  REJECTED = 'rejected',
  ARCHIVED = 'archived',
}

export type Project = Auditable & {
  id: string;
  state?: ProjectState;
  user?: number;
  published_course?: string;
  unpublished_course?: string;
  courses?: string[];
  /**
   * true if there are major changes to the course, and needs review
   * false if there are minor changes to the course, and can be published
   */
  has_substantial_changes?: boolean;
};

export type Annotation = Auditable & {
  id?: string;
  object_id: string;
  field: string;
  feedback: string;
  required_action: boolean;
  is_resolved: boolean;
  content_type: number | ContentTypeModels; // Add ContentTypeModels to fix the undefined model for content type
  course: string;
  annotation_count?: number | undefined;
  content: {
    id: string;
    type: string;
    details: {
      name: string;
    };
  };
};

export type ContentType = {
  id: number;
  app_label: ContentTypeApps;
  model: ContentTypeModels;
};

export type StudentProgressV2 = Auditable & {
  id: string;
  is_completed: boolean;
  /** foreign key id */
  subsection: string;
  /** foreign key id */
  user: string;
};
export type StudentAssignmentV2 = Auditable & {
  id: string;
  /** foreign key id */
  assignment: string;
  /** foreign key id */
  user: string;
};
export type StudentAssignmentAnswerV2 = Auditable & {
  id: string;
  /** foreign key id: course_assignment_question */
  question: string;
  /** foreign key id */
  student_assignment: string;
  answer: string;
  feedback: string;
};
export type StudentQuizV2 = Auditable & {
  id: string;
  /** foreign key id */
  quiz: string;
  /** foreign key id */
  user: string;
};
export type StudentQuizAnswerV2 = Auditable & {
  id: string;
  /** foreign key id: course_quiz_answer */
  answer: string;
  /** foreign key id: course_quiz_question */
  question: string;
  /** foreign key id */
  user: string;
};

export enum CourseSkillLevel {
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  EXPERT = 'expert',
  ALL = 'all',
}

export interface CourseListPayload {
  project_state?: ProjectState;
  price?: string;
  is_popular?: string;
  subcategory?: string;
  category?: string;
  topic?: string;
  language?: string;
  level?: CourseSkillLevel[];
  rating?: string;
  variety?: string;
  page?: number;
  page_size?: number;
  tabfilter?: string;
  title?: string;
  description?: string;
}

export type CourseQnAResponse = {
  next: string | null;
  previous: string | null;
  count: number;
  results: Partial<CourseQnA[]>;
};

export interface CourseQnAReply {
  id: string;
  user: User;
  reply_count: number;
  upvoted_users_count: number;
  date_created: string;
  comment: string;
  qna: string;
}
export type CourseQnAReplyPayload = {
  comment: string;
};

export type CourseQnA = {
  id: string;
  user: User;
  reply_count: number;
  upvoted_users_count: number;
  lecture: any;
  date_created: string;
  title: string;
  description: string;
  approved: boolean;
  not_relevant: boolean;
  subsection: string | null;
  project: string;
  replies?: CourseQnAReply[];
};

export type CourseQnAPayload = {
  title: string;
  description: string;
  subsection_id?: string;
  approved?: boolean;
};

export type CourseAssignmentAnswer = Auditable & {
  id?: string;
  question_id: string;
  answer: string;
};

// Quiz
export type CourseQuizAnswer = Auditable & {
  id?: string;
  date_created?: string;
  date_updated?: string;
  question_id: string;
  answer_id: string;
  is_correct?: boolean;
};

export type CourseQuizAnswerPayload = {
  content: CourseQuizAnswer[];
};

// Curriculum section and subsection position changes payload
export interface SectionPosition {
  position: number;
  section: string;
}
export interface SubsectionPosition {
  position: number;
  subsection: string | undefined;
}

export interface VideoLibraryCourse {
  id: string;
  title: string;
  sections: VideoLibraryCoursesSubsection[];
}

export interface VideoLibraryCoursesSubsection {
  id: string;
  title: string;
  position: number;
}
