export interface CourseDetailsModelV2 {
  id: string;
  code: string;
  chain_id: string;
  title: string;
  slug: string;
  is_free: boolean;
  pricing: string;
  subtitle: string;
  desc: string;
  image: string;
  is_promote: boolean;
  skill_level: string;
  category: string;
  subcategory: string;
  language: string;
  topics: string[];
  course_type: string;
  status?: CourseStatusV2;
  included_in_promo: boolean;
  date_published: string | null;
  is_deleted: boolean;
  project: string;
  version: string;
  date_updated: string;
  date_created: string;
  category_name: string;
  unpublished_project: UnpublishedProject[];
  source: string;
  video_info: videoInfoV2;
  user: UserV2 | any;
  image_url?: string;
  currency?: number;
  currency_preview?: number;
  promo_video?: string;
}

export interface UnpublishedProject {
  id: string;
  published_course: string;
  unpublished_course: string;
  state: string;
  has_substantial_changes: boolean;
  user: number;
  date_created: string;
  date_updated: string;
}

export interface UserV2 {
  id: number;
  first_name: string;
  last_name: string;
  bio: string;
  photo: string;
  fullname: string;
  photo_url: string;
  headline: string;
  language: string;
  is_active: boolean;
  public_profile: string;
}

export interface videoInfoV2 {
  library?: string;
  status: string;
  event?: string;
  error?: string;
}

export enum CourseStatusV2 {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  REJECTED = 'rejected',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

// TODO: Need to remove unnecessary data from response
// Current Response
// {
//    "id": "1f9bcb35-3e5c-48ac-9d41-900a40a1fdc3",
//    "code": "8a024f51-e2cc-47d0-bce9-2a1ea2c4a4e2",
//    "chain_id": "16cca5d9-74fb-4c3d-b348-63a70d96bf96",
//    "user": {
//      "id": 15,
//      "first_name": "John",
//      "last_name": "Doe11",
//      "bio": "John Doe is a seasoned software developer with over a decade of experience in web development, specializing in Angular. As a passionate educator and mentor, John has dedicated the last five years to teaching Angular to aspiring developers, guiding them through the intricacies of modern web applications. His deep understanding of Angular’s architecture, combined with his practical experience, allows him to break down complex concepts into easily digestible lessons.\n\nJohn’s career began in full-stack development, where he quickly gravitated towards front-end frameworks. His enthusiasm for Angular led him to contribute to several open-source projects, and he remains an active participant in the Angular community. Known for his engaging teaching style, John integrates real-world examples and hands-on projects into his courses, ensuring students not only learn the theory but also apply it effectively.",
//      "photo": "user=15/library=6a83d37a-5464-43e9-9ff2-fd845757262e/6a83d37a-5464-43e9-9ff2-fd845757262e.png",
//      "fullname": "John Doe11",
//      "photo_url": "https://d2zyq1d2fqk65y.cloudfront.net/user=15/library=6a83d37a-5464-43e9-9ff2-fd845757262e/6a83d37a-5464-43e9-9ff2-fd845757262e.png",
//      "headline": "We are sailing north",
//      "language": "en",
//      "is_active": true,
//      "public_profile": ""
//    },
//    "title": "Internet and Web Development Fundamentals",
//    "slug": "internet-and-web-development-fundamentals-7",
//    "is_free": true,
//    "pricing": "76ec4f20-a9f0-4045-a74d-d86270f4a8f2",
//    "subtitle": "Internet and Web Development Fundamentals",
//    "desc": "Massive new React \"expansion pack\" covers: React basics, JSX, props, state, Vite, MaterialUI, hooks, useEffect, React design patterns, and more.\n\nHi! Welcome to the brand new version of The Web Developer Bootcamp, Udemy's most popular web development course.  This course was just completely overhauled to prepare students for the 2023 job market, with over 60 hours of brand new content. This is the **only course you need to learn web development. **There are a lot of options for online developer training, but this course is without a doubt the most comprehensive and effective on the market.  Here's why:\n\n*",
//    "description": {},
//    "image": "user=15/content_type=images/library=d15b7acb-a85e-41c1-88d1-4e335c51874a/d15b7acb-a85e-41c1-88d1-4e335c51874a.jpg",
//    "promo_video": null,
//    "video_info": {},
//    "is_promote": false,
//    "skill_level": "all",
//    "category": "a0136c4e-155d-4f8f-8412-97768fb60fe2",
//    "price_snapshot": "0.00",
//    "currency_snapshot": "SAR",
//    "subcategory": "c9a9810a-22a8-41a9-b460-9a3c236a38cf",
//    "language": "en",
//    "topics": [
//      "3e9853c8-0550-446b-8851-e1c4bf961f8c",
//      "4968044f-e961-478d-95b2-76ebb99b2018"
//    ],
//    "average_rating": 0,
//    "total_reviews": 0,
//    "course_type": "video_based",
//    "status": "in_review",
//    "begin_msg": null,
//    "completion_msg": null,
//    "included_in_promo": false,
//    "date_published": null,
//    "is_deleted": false,
//    "image_url": "https://d2zyq1d2fqk65y.cloudfront.net/user=15/content_type=images/library=d15b7acb-a85e-41c1-88d1-4e335c51874a/d15b7acb-a85e-41c1-88d1-4e335c51874a.jpg",
//    "project": "a15eb221-be5d-4abe-ad10-09dfb8ce0285",
//    "version": "1.4",
//    "date_updated": "2024-09-14T18:48:58.909237Z",
//    "date_created": "2024-09-14T18:48:58.724317Z",
//    "currency_symbol": " SR",
//    "currency_code": "SAR",
//    "currency": 1,
//    "category_name": "Technology",
//    "course_sold": 2,
//    "course_curriculum": {
//      "total_sections": 1,
//      "total_lectures": 6
//    },
//    "unpublished_project": [
//      {
//        "id": "a15eb221-be5d-4abe-ad10-09dfb8ce0285",
//        "published_course": "f7f54333-7dc9-40d8-bb8e-2548ff01b0df",
//        "unpublished_course": "1f9bcb35-3e5c-48ac-9d41-900a40a1fdc3",
//        "state": "in_review",
//        "has_substantial_changes": true,
//        "user": 15,
//        "courses": [
//          "148dcf03-85a1-4a57-8aa3-f49094d39f31",
//          "f7f54333-7dc9-40d8-bb8e-2548ff01b0df",
//          "a9b28915-950b-4c95-bdc0-3fc21fc2cf04",
//          "8c2afdc4-3c8f-45b9-a1c1-531fcb099480",
//          "1d39670f-7a77-4046-bf99-07a062398761",
//          "1f9bcb35-3e5c-48ac-9d41-900a40a1fdc3"
//        ],
//        "date_created": "2024-08-07T06:53:31.100193Z",
//        "date_updated": "2024-09-14T18:48:59.367393Z"
//      }
//    ],
//    "source": "1d39670f-7a77-4046-bf99-07a062398761"
//  }
