<div class="relative" [ngClass]="isDisabled ? 'cursor-not-allowed opacity-[0.5]' : ''">
  <div *ngIf="isDisabled" class="absolute left-0 top-0 z-50 h-full w-full"></div>
  <app-main-editor
    [topBarItemsConfig]="topBarItemsConfig"
    [isTopBard]="isTopBard"
    [isActiveSlash]="isActiveSlash"
    [isActiveBlock]="isActiveBlock"
    [value]="value"
    [isDisabled]="isDisabled"
    [isActiveTooltip]="isActiveTooltip"
    [outputFormate]="outputFormate"
    (editorValue)="editorValue($event)"
  >
  </app-main-editor>
</div>
