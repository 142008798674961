import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { FieldType, FormlyFieldProps } from '@ngx-formly/bootstrap/form-field';
import { FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { ModalComponent } from '@shared';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { Uppy, UppyFile } from '@uppy/core';
import Dashboard, { DashboardOptions } from '@uppy/dashboard';
import DragDrop from '@uppy/drag-drop';
import GoldenRetriever from '@uppy/golden-retriever';
import { StatusBarOptions } from '@uppy/status-bar';
import * as _ from 'lodash';
import { uniqueId } from 'lodash';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { SelectVideoLibraryItem } from '../../../models';
import { LoaderService, Logger, ToastService } from '../../../services';
import { RouterStoreService, selectCourse, selectSelectedActivityIdV2, selectUploadsV2 } from '../../../store';

const log = new Logger('FormlyFieldFile');

const cloudfrontURL: string = 'https://d13439oyxvz2md.cloudfront.net/';

type UploadInfo = {
  name: string;
  type: string;
};

type ProgressInfo = {
  progressPercent: number;
  bytesUploaded: number;
  bytesTotal: number;
};
export interface dragdropState {
  fileName: string;
  currentProgress: number;
  viewState: 'file-added' | 'upload-progress' | 'upload-success' | 'complete' | '';
  fileURL: string;
}
export interface FileProcessingState {
  size?: number;
  error?: string;
  event?: string;
  status?: string;
  duration?: number;
  task_token?: string;
  course_id?: string;
}

export interface FileProps extends FormlyFieldProps {
  preview?: boolean;
  previewResource?: 'avatar';
  previewUrl?: string;
  previewType: 'image' | 'video' | 'file';
  allowedTypes?: string[];
  uploader?: 'dashboard' | 'device';
  uploadType: 'dashboard' | 'drag-drop' | 'default';
  metadata?: Record<string, string>;
  metadataFn?: () => Record<string, any>;
  onUpload?: (upload: UploadInfo, field: FormlyFieldConfig) => void;
  onProgress?: (progress: ProgressInfo, field: FormlyFieldConfig) => void;
  onSuccess?: (field: FormlyFieldConfig) => void;
  onComplete?: (field: FormlyFieldConfig) => void;
  onAbort?: (field: FormlyFieldConfig) => void;
  onRetry?: (status: string, field: FormlyFieldConfig) => void;
  componentRef?: any;
  disabled?: boolean;
  processingStatus?: FileProcessingState;
  sources?: SelectVideoLibraryItem;
  subheading?: string;
  enableHotkey?: boolean;
}
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'formly-field-file',
  templateUrl: './file.component.html',
  styles: [
    `
      :host {
        .tooltip-items {
          @apply invisible absolute -top-20 z-10 min-w-[280px] opacity-0 max-sm:-top-36 max-sm:min-w-[216px];
        }
        .tooltip-items::before {
          @apply absolute -bottom-[14px] border-8 border-slate-700 border-b-transparent border-l-transparent border-r-transparent;
          content: '';
        }
        .tooltip-container {
          @apply relative;
          &:hover {
            .tooltip-items {
              @apply visible opacity-100;
            }
          }
        }
      }
    `,
  ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FormlyFieldFile extends FieldType<FieldTypeConfig<FileProps>> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('dashboardUploader', { read: ElementRef<HTMLElement> }) defaultDragDropContainer?: ElementRef<HTMLElement>;
  static uppyMap: Map<string, Uppy> = new Map();

  @ViewChild('uploadModal') uploadModal?: ModalComponent;
  showPlaceholder: boolean = false;

  uppyFileTypes: string[] = [];
  uppy!: Uppy;

  state$ = new BehaviorSubject<dragdropState>({
    fileName: 'Uploading...',
    currentProgress: 0,
    viewState: '',
    fileURL: '',
  });
  sources$ = new BehaviorSubject<any>({});
  enableHotkey: boolean = false;
  private fileKey: string = '';

  readonly uppyStatusBarOptions: StatusBarOptions = {
    showProgressDetails: true,
    hideUploadButton: true,
  };
  readonly uppyDashboardOptions: DashboardOptions = {
    id: uniqueId('file'),
    hideUploadButton: true,
    hideRetryButton: true,
    showSelectedFiles: false,
    showRemoveButtonAfterComplete: false,
    browserBackButtonClose: true,
    showProgressDetails: true,
    closeAfterFinish: false,
    closeModalOnClickOutside: true,
    autoOpenFileEditor: true,
    proudlyDisplayPoweredByUppy: false,
    note: '.mp4, .mov, .mkv, .webp, .jpg, .jpeg, or .png upto 1GB',
  };

  course$ = this.store.select(selectCourse);
  courseId$ = this.routerStore.getParam('courseId').pipe(tap((v) => console.log('courseId$: ', v)));

  private subscriptions = new Subscription();
  processingStatus: string = '';
  isLoading$ = this.loaderService.response('file-processing');
  uploads$ = this.store.select(selectUploadsV2);
  lectureId$ = this.store.select(selectSelectedActivityIdV2);

  processingStatus$ = new BehaviorSubject<string>('');

  constructor(
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private readonly store: Store,
    private readonly routerStore: RouterStoreService,
    private loaderService: LoaderService,
    private toastService: ToastService
  ) {
    super();
  }

  ngOnInit() {
    this.enableHotkey = this.props.enableHotkey ?? false;
    this.sources$.next({
      ...this.sources$.value,
      ...this.props.sources,
    });
    this.state$.next({
      ...this.state$.value,
      fileURL: this.props.previewUrl ?? '',
      viewState: this.props.previewUrl ? 'complete' : '',
      fileName: this.props.previewUrl?.split('/').pop() ?? '',
    });
    this.props.componentRef = this;
    if (!this.props.uploadType) {
      this.props.uploadType = 'default';
    }
    if (this.props.allowedTypes?.length) {
      const fileExtensions = this.props.allowedTypes.map((mimeType) => {
        const parts = mimeType.split('/');
        return '.' + parts[1];
      });
      this.uppyFileTypes = fileExtensions;
    }

    const uppy = FormlyFieldFile.uppyMap.get(this.formControl.value);
    if (uppy) {
      this.uppy = uppy;
    } else {
      if (this.props.uploadType === 'dashboard') {
        // Uppy Dashboard
        this.uppy = new Uppy({
          id: uniqueId('file'),
          debug: false,
          autoProceed: true,
          restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: this.uppyFileTypes,
          },
          meta: {
            ...this.props.metadata,
            ...(this.props.metadataFn && this.props.metadataFn()),
          },
        })
          .use(Dashboard, {})
          .use(GoldenRetriever, {
            serviceWorker: true,
          })
          .use(AwsS3Multipart, {
            companionUrl: environment.uppyCompanionUrl,
            retryDelays: [0, 1000, 3000, 5000],
          });
      } else if (this.props.uploadType === 'drag-drop') {
        // Uppy Drag and Drop
        this.uppy = new Uppy({
          id: uniqueId('file'),
          debug: false,
          autoProceed: true,
          restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: this.uppyFileTypes,
          },
          meta: {
            ...this.props.metadata,
            ...(this.props.metadataFn && this.props.metadataFn()),
          },
          locale: {
            strings: {
              dropHereOr: 'Upload a file %{browse}',
              browse: 'or drag and drop',
            },
          },
        })
          .use(DragDrop, {})
          .use(GoldenRetriever, {
            serviceWorker: true,
          })
          .use(AwsS3Multipart, {
            companionUrl: environment.uppyCompanionUrl,
            retryDelays: [0, 1000, 3000, 5000],
          });
      } else {
        // Uppy Default
        this.uppy = new Uppy({
          id: uniqueId('file'),
          debug: false,
          autoProceed: true,
          restrictions: {
            maxNumberOfFiles: 1,
            allowedFileTypes: this.uppyFileTypes,
          },
          meta: {
            ...this.props.metadata,
            ...(this.props.metadataFn && this.props.metadataFn()),
          },
          locale: {
            strings: {
              dropHereOr: '%{browse}',
              browse: 'Choose File',
            },
          },
        })
          .use(DragDrop, {})
          .use(AwsS3Multipart, {
            companionUrl: environment.uppyCompanionUrl,
            retryDelays: [0, 1000, 3000, 5000],
          });
      }
    }

    this.subscriptions.add(
      this.isLoading$.subscribe((res) => {
        this.processingStatus = res;
        this.processingStatus$.next(res);
      })
    );
  }

  ngAfterViewInit(): void {
    this.updateNote();
    this.uploadModal?.close();
    this.initUppyCallbacks();
  }

  private getMetadata() {
    return {
      ...this.props.metadata,
      ...(this.props.metadataFn && this.props.metadataFn()),
    };
  }

  private initUppyCallbacks() {
    // Define common event handlers
    this.uppy.on('file-added', (file: UppyFile) => {
      this.validateFile(this.uppy, file);
      this.uppy.setMeta(this.getMetadata());
      // this.state$.next({ ...this.state$.value, viewState: 'file-added' });
    });
    this.uppy.on('upload-progress', (file, progress) => {
      // console.log('upload-progress', file, progress);
      const progressPercent = Math.floor((progress.bytesUploaded / progress.bytesTotal) * 100);
      this.progress({
        bytesTotal: progress.bytesTotal,
        bytesUploaded: progress.bytesUploaded,
        progressPercent,
      });
      this.state$.next({ ...this.state$.value, viewState: 'upload-progress', currentProgress: progressPercent });
      // this.loaderService.loaderAction('file-processing', 'upload-progress');
      // console.log('state$', this.state$.value);
    });
    this.uppy.on('upload', (...args: any[]) => {
      // console.log('upload', args[0]);
      this.printArgs('upload', ...args);
      this.uploadModal?.close();
      this.state$.next({ ...this.state$.value, viewState: 'upload-progress' });
      // this.loaderService.loaderAction('file-processing', 'upload-progress');
      // console.log('state$', this.state$.value);
    });
    this.uppy.on('upload-success', (resp: any) => {
      this.printArgs('upload-success', resp);
      this.field.formControl.setValue(resp.s3Multipart.key);
      this.state$.next({ ...this.state$.value, viewState: 'upload-success' });
      // this.loaderService.loaderAction('file-processing', 'upload-success');
      this.cleanup();
      // console.log('state$', this.state$.value);
    });
    this.uppy.on('complete', (...args: any) => {
      this.printArgs('complete', ...args);
      this.complete();
      this.cleanup();
      // this.field.formControl.setValue(args.successful[0].s3Multipart.key);
      setTimeout(() => {
        const previewUrl = cloudfrontURL + { ...this.state$.value }.fileURL;
        this.state$.next({
          ...this.state$.value,
          viewState: 'complete',
          fileURL: this.props?.previewUrl ?? previewUrl,
        });
        // this.loaderService.loaderAction('file-processing', 'complete');
        // console.log('state$', this.state$.value);
      }, 3000);
    });
    this.uppy.on('error', (...args: any[]) => {
      this.printArgs('error', ...args);
      this.cleanup();
    });
    this.uppy.on('upload-error', (...args: any[]) => {
      this.printArgs('upload-error', ...args);
      this.cleanup();
    });
    this.uppy.on('cancel-all', (...args: any[]) => {
      this.printArgs('cancel-all', ...args);
      this.props.onAbort?.(this.field);
      this.cleanup();
    });
  }

  validateFile(uppySetup: Uppy, file: UppyFile) {
    if (this.bytesToGigabytes(file.size) > 2) {
      uppySetup.removeFile(file.id);
      uppySetup.info(`File size to large: ${file.name}`);
      this.toastService.error({
        type: 'message',
        message: $localize`File size should not be more than 2GB`,
      });
      return;
    }
    // Validate Type
    if (this.props.allowedTypes) {
      if (file?.type && !this.props.allowedTypes.includes(file?.type)) {
        uppySetup.removeFile(file.id);
        uppySetup.info(`Invalid file type: ${file.name}`, 'info');
        return;
      }
    }
    // console.log('file', file);

    // set formControl value
    this.fileKey = this.getFileKey(file);
    this.formControl.setValue(this.fileKey);

    // keep reference to uppy when reopening this specific file while upload is ongoing
    FormlyFieldFile.uppyMap.set(this.fileKey, uppySetup);

    // emit file info
    this.props.onUpload?.(
      {
        name: file.name,
        type: file.type ?? '',
      },
      this.field
    );
    this.state$.next({ ...this.state$.value, fileURL: this.fileKey, fileName: file.meta.name });
  }

  cleanup(): void {
    FormlyFieldFile.uppyMap.delete(this.fileKey);
  }

  handleFileInfo(event: string) {
    if (event == 'open') {
      if (this.props.uploader === 'device') {
        this.clickUploader();
      } else {
        this.uploadModal?.open();
      }
    }
    if (event == 'reset') {
      this.formControl.setValue('');
      this.props.previewUrl = '';
      this.props.processingStatus = {};
      this.processingStatus = '';
      this.uppy.cancelAll();
      this.state$.next({
        fileName: 'Uploading...',
        currentProgress: 0,
        viewState: '',
        fileURL: '',
      });
      this.updateNote();
    }
    if (event == 'abort') {
      this.uppy.cancelAll();
      this.props.processingStatus = {};
      this.processingStatus = '';
      this.state$.next({
        fileName: 'Uploading...',
        currentProgress: 0,
        viewState: '',
        fileURL: '',
      });
      this.updateNote();
    }
    if (event === 'retry') {
      this.formControl.setValue('');
      this.props.previewUrl = '';
      this.props.processingStatus = {};
      this.processingStatus = '';
      this.uppy.cancelAll();
      this.state$.next({
        fileName: 'Uploading...',
        currentProgress: 0,
        viewState: '',
        fileURL: '',
      });
      this.updateNote();
      this.props.onRetry?.(event, this.field);
    }
  }

  updateNote() {
    // Update uppy note to placeholder
    setTimeout(() => {
      const element = this.elementRef.nativeElement.querySelector('.uppy-DragDrop-note');
      if (element) {
        element.textContent = this.props.placeholder;
      }
    }, 500);
  }

  sanitizeUrl(url?: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url ?? '');
  }

  private clickUploader() {
    this.defaultDragDropContainer?.nativeElement.querySelector('input')?.click();
  }

  private printArgs = <T extends any[]>(source: string, ...args: T): void => {
    // args.forEach((arg, idx) => {
    //   if (arg) {
    //     console.log(`Param Idx: ${idx}; Source: ${source}; Type: ${typeof arg}; Value: `, arg);
    //   }
    // });
  };

  private getFileKey(file: UppyFile): string {
    // NOTE: If required file path change
    // Omit the key which is not required from meta like: 'apihost','content_type','library','name','public','relativePath','type','user'.
    let folder = '';
    // Object.entries(_.omit(file.meta, ['name', 'type', 'relativePath'])).forEach(([key, value]) => {
    //   folder += `${key}=${value}/`;
    // });

    // return `${folder}${file.meta.name}`;

    Object.entries(
      _.omit(file.meta, ['name', 'type', 'relativePath', 'public', 'apihost', 'course', 'lecture', 'resource'])
    ).forEach(([key, value]) => {
      folder += `${key}=${value}/`;
    });
    let filext = file.meta.name.split('.').pop();
    let keyName = `${folder}${file.meta['library']}.${filext}`;
    return keyName;
  }

  private readonly progress = _.throttle((progress: ProgressInfo) => {
    this.props.onProgress?.(progress, this.field);
  }, 500);

  private complete(): void {
    this.props.onComplete?.(this.field);
    // reset the old file to be able to upload the new file later
    this.uppy.cancelAll();
  }

  /**
   * Converts bytes to gigabytes.
   * @param bytes - The number of bytes to convert.
   * @returns The equivalent size in gigabytes.
   */
  bytesToGigabytes(bytes: number): number {
    const BYTES_IN_ONE_GIGABYTE = 1_073_741_824; // 1024^3
    return bytes / BYTES_IN_ONE_GIGABYTE;
  }

  // Preview image error
  fallbackImage = 'assets/images/static/fallback.png';
  onImageError(event: Event): void {
    const target = event.target as HTMLImageElement;
    // Prevent infinite loop by checking if the current src is already the fallback image
    if (target.src.includes(this.fallbackImage)) {
      return;
    }
    target.src = this.fallbackImage;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
