import { createFeature, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import {
  CourseCurriculumSectionV2,
  PartialEntity,
  StreamVideoInfoV3,
  SubsectionDetailV3,
  SubsectionTypeV3,
  UploadStateV2,
} from '../../models';
import { CourseActionsV2 } from './course.actions';
import { CourseStateV2, initialCourseStateV2 } from './course.state';

// Video processing
let pendingUpdates: StreamVideoInfoV3[] = [];
let updateTimeout: any = null;
const processUpdates = (state: CourseStateV2, updates: StreamVideoInfoV3[]) => {
  const updatedSections = state.courseCurriculum.results.map((section) => {
    return {
      ...section,
      subsections: section.subsections.map((section: PartialEntity<SubsectionDetailV3>) => {
        const update = updates.find((u) => u.lecture_id === section?.lecture?.id);
        return update
          ? { ...section.lecture, video_info: { ...section?.lecture?.video_info, ...update } }
          : section.lecture;
      }),
    };
  });

  const updatedState = {
    ...state,
    courseCurriculum: {
      ...state.courseCurriculum,
      results: updatedSections,
    },
    isProcessingUpdates: false,
  };
  return updatedState as CourseStateV2;
};

export const courseDetailsV2Reducer = createReducer(
  initialCourseStateV2,
  // Course details
  on(CourseActionsV2.loadCourseDetailsSuccess, (state, data) => {
    return {
      ...state,
      courseDetails: data,
    };
  }),

  on(CourseActionsV2.upsertCourseSuccess, (state, { courseDetails }) => {
    return {
      ...state,
      courseDetails: {
        ...courseDetails,
      },
    };
  }),

  // Course curriculum
  on(CourseActionsV2.loadCourseCurriculumSuccess, (state, data) => {
    return {
      ...state,
      courseCurriculum: data,
    };
  }),

  on(CourseActionsV2.autoLoadCourseCurriculumSuccess, (state, data) => {
    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: [...state.courseCurriculum.results, ...data.results],
      },
    };
  }),

  on(CourseActionsV2.upsertCourseCurriculum, (state, { results }) => {
    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: results,
      },
    };
  }),

  on(CourseActionsV2.upsertCourseSubsection, (state, { results, sectionId }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === sectionId) {
        return {
          ...section,
          subsections: results,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections,
      },
    };
  }),

  // Section
  on(CourseActionsV2.createSectionV2, (state, { section }) => {
    const sections = _.cloneDeep(state.courseCurriculum.results);

    const newSection: CourseCurriculumSectionV2 = {
      id: section.id!,
      title: section.title || 'Untitled Section',
      position: section.position || sections.length + 1,
      subsections: section.subsections || [],
      date_updated: section.date_updated || new Date().toISOString(),
      source: section.source,
    };

    const updatedSections = [...sections, newSection];

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections,
      },
    };
  }),

  on(CourseActionsV2.upsertSectionV2, (state, { section }) => {
    const sections = _.cloneDeep(state.courseCurriculum.results);

    const index: number = sections.findIndex((s) => s.id === section.id);
    const updatedSection = sections[index];
    const newSection: CourseCurriculumSectionV2 = {
      ...updatedSection,
      ...section,
    };
    let updatedSections = sections.map((s, i) => (i === index ? newSection : s));
    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections,
      },
    };
  }),

  on(CourseActionsV2.deleteSectionV2, (state, { sectionId }) => {
    const sections = _.cloneDeep(state.courseCurriculum.results);
    const updatedSections = sections.filter((s) => s.id !== sectionId);
    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections,
      },
    };
  }),

  on(CourseActionsV2.deleteSubsectionV2, (state, { sectionId, subsectionId }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === sectionId) {
        const updatedSubsections = section.subsections.filter((s) => s.id !== subsectionId);

        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections,
      },
    };
  }),

  // Lecture
  on(CourseActionsV2.createLectureV2, (state, { subsection, lecture }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === subsection.section) {
        const newSubsection: SubsectionDetailV3 = {
          id: subsection.id,
          type: subsection.type as SubsectionTypeV3,
          position: section.subsections.length + 1,
          quiz: undefined,
          lecture: {
            ...lecture,
            date_created: new Date().toISOString(),
            date_updated: new Date().toISOString(),
          },
          assignment: undefined,
          section: subsection.section,
          date_updated: new Date().toISOString(),
        };

        return {
          ...section,
          subsections: [...section.subsections, newSubsection as SubsectionDetailV3],
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as CourseCurriculumSectionV2[],
      },
      selectedSubsectionId: subsection.id,
      selectedActivityId: lecture.id,
      // isActivityModalOpen: false,
    };
  }),

  on(CourseActionsV2.upsertLectureV2, (state, { lecture, lectureResources }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === lecture.section) {
        // Find the matching subsection to update the lecture
        const updatedSubsections = section.subsections.map((subsection) => {
          if (subsection.lecture?.id === lecture.id) {
            return {
              ...subsection,
              lecture: {
                ...subsection.lecture,
                ...lecture,
                resources: [...lectureResources],
                date_updated: new Date().toISOString(),
              },
              date_updated: new Date().toISOString(),
            };
          }
          return subsection;
        });

        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections,
      },
    };
  }),

  on(CourseActionsV2.deleteLectureResourceV2, (state, { lectureResourceId, sectionId, subsectionId }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === sectionId) {
        const updatedSubsections = section.subsections.map((subsection) => {
          if (subsection.id === subsectionId && subsection.type === 'lecture') {
            const filteredQuestions =
              subsection.lecture?.resources?.filter((resource) => resource.id !== lectureResourceId) || [];
            return {
              ...subsection,
              lecture: {
                ...subsection.lecture,
                resources: filteredQuestions,
              },
            };
          }
          return subsection;
        });
        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as any,
      },
    };
  }),

  // on(CourseActionsV2.upsertLectureVideoInfoV2, (state, { video_info }) => {
  //   // TODO: Take lecture id from provided 'video_info',
  //   // TODO: Find subsection based on the lecture_id which coming with 'video_info'
  //   // TODO: Update the video_info inside subsection > lecture > video_info
  //   // NOTE: Do this process asynchronous way because this action will be call lot of time with lot of subsection update every time

  //   // Video info:

  //   let newState = {
  //     ...state,
  //     // TODO: Set updated data in store
  //   };

  //   return newState;
  // }),

  on(CourseActionsV2.upsertLectureVideoInfoV2, (state, { video_info }) => {
    const { lecture_id } = video_info;
    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: state.courseCurriculum.results?.map((section) => ({
          ...section,
          subsections: section.subsections?.map((subsection) => {
            if (subsection.type === 'lecture' && subsection.lecture?.id === lecture_id) {
              const newSubsection = {
                ...subsection,
                lecture: {
                  ...subsection.lecture,
                  type: 'video',
                  video_info: {
                    ...subsection.lecture?.video_info,
                    ...video_info, // Merge the updated video_info
                  },
                },
              };
              return newSubsection;
            }

            return subsection;
          }),
        })),
      },
    } as CourseStateV2;
  }),

  // Quiz
  on(CourseActionsV2.createQuizV2, (state, { subsection, quiz }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === subsection.section) {
        const newSubsection: SubsectionDetailV3 = {
          id: subsection.id,
          type: subsection.type as SubsectionTypeV3,
          position: section.subsections.length + 1,
          lecture: undefined,
          quiz: {
            ...quiz,
            date_created: new Date().toISOString(),
            date_updated: new Date().toISOString(),
          },
          assignment: undefined,
          section: subsection.section,
          date_updated: new Date().toISOString(),
        };

        return {
          ...section,
          subsections: [...section.subsections, newSubsection as SubsectionDetailV3],
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as CourseCurriculumSectionV2[],
      },
      selectedSubsectionId: subsection.id,
      selectedActivityId: quiz.id,
    };
  }),

  on(CourseActionsV2.upsertQuizV2, (state, { quiz }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === quiz.section) {
        const updatedSubsections = section.subsections.map((subsection) => {
          if (subsection.quiz?.id === quiz.id) {
            return {
              ...subsection,
              quiz: {
                ...subsection.quiz,
                ...quiz,
                date_updated: new Date().toISOString(),
              },
              date_updated: new Date().toISOString(),
            };
          }
          return subsection;
        });

        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections,
      },
    };
  }),

  on(CourseActionsV2.createQuizQuestionV2, (state, { quizQuestion, quizAnswers, sectionId, subsectionId }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === sectionId) {
        const updatedSubsections = section.subsections.map((subsection) => {
          if (subsection.id === subsectionId && subsection.type === 'quiz') {
            const updatedQuiz = {
              ...subsection.quiz,
              id: subsection.quiz?.id || quizQuestion.quiz,
              questions: [
                ...(subsection.quiz?.questions || []),
                {
                  ...quizQuestion,
                  answers: quizAnswers,
                },
              ],
            };
            return {
              ...subsection,
              quiz: updatedQuiz,
            };
          }
          return subsection;
        });
        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as any,
      },
    };
  }),

  on(CourseActionsV2.upsertQuizQuestionV2, (state, { quizQuestion, quizAnswers, sectionId, subsectionId }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === sectionId) {
        const updatedSubsections = section.subsections.map((subsection) => {
          if (subsection.id === subsectionId && subsection.type === 'quiz') {
            const updatedQuestions =
              subsection.quiz?.questions?.map((question) =>
                question.id === quizQuestion.id ? { ...quizQuestion, answers: quizAnswers } : question
              ) || [];
            return {
              ...subsection,
              quiz: {
                ...subsection.quiz,
                questions: updatedQuestions,
              },
            };
          }
          return subsection;
        });
        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as any,
      },
    };
  }),

  on(CourseActionsV2.deleteQuizQuestionV2, (state, { quizQuestionId, sectionId, subsectionId }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === sectionId) {
        const updatedSubsections = section.subsections.map((subsection) => {
          if (subsection.id === subsectionId && subsection.type === 'quiz') {
            const filteredQuestions =
              subsection.quiz?.questions?.filter((question) => question.id !== quizQuestionId) || [];
            return {
              ...subsection,
              quiz: {
                ...subsection.quiz,
                questions: filteredQuestions,
              },
            };
          }
          return subsection;
        });
        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as any,
      },
    };
  }),

  on(CourseActionsV2.deleteQuizAnswerV2, (state, { quizAnswerId, quizQuestionId, sectionId, subsectionId }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === sectionId) {
        const updatedSubsections = section.subsections.map((subsection) => {
          if (subsection.id === subsectionId && subsection.type === 'quiz') {
            const updatedQuestions =
              subsection.quiz?.questions?.map((question) => {
                if (question.id === quizQuestionId) {
                  const filteredAnswers = question.answers.filter((answer) => answer.id !== quizAnswerId);
                  return {
                    ...question,
                    answers: filteredAnswers,
                  };
                }
                return question;
              }) || [];

            return {
              ...subsection,
              quiz: {
                ...subsection.quiz,
                questions: updatedQuestions,
              },
            };
          }
          return subsection;
        });

        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as any,
      },
    };
  }),

  // Assignments
  on(CourseActionsV2.createAssignmentV2, (state, { subsection, assignment }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === subsection.section) {
        const newSubsection: SubsectionDetailV3 = {
          id: subsection.id,
          type: subsection.type as SubsectionTypeV3,
          position: section.subsections.length + 1,
          quiz: undefined,
          lecture: undefined,
          assignment: {
            ...assignment,
            date_created: new Date().toISOString(),
            date_updated: new Date().toISOString(),
          },
          section: subsection.section,
          date_updated: new Date().toISOString(),
        };

        return {
          ...section,
          subsections: [...section.subsections, newSubsection as SubsectionDetailV3],
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as CourseCurriculumSectionV2[],
      },
      selectedSubsectionId: subsection.id,
      selectedActivityId: assignment.id,
    };
  }),

  on(CourseActionsV2.upsertAssignmentV2, (state, { assignment, assignmentQuestions }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      const updatedSubsections = section.subsections.map((subsection) => {
        if (subsection.assignment?.id === assignment.id) {
          const updatedAssignment = {
            ...subsection.assignment,
            ...assignment,
            questions: assignmentQuestions.map((question) => ({
              ...question,
              date_updated: new Date().toISOString(),
            })),
            date_updated: new Date().toISOString(),
          };

          return {
            ...subsection,
            assignment: updatedAssignment,
            date_updated: new Date().toISOString(),
          };
        }
        return subsection;
      });

      return {
        ...section,
        subsections: updatedSubsections,
      };
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as any,
      },
    };
  }),

  on(CourseActionsV2.deleteAssignmentQuestionV2, (state, { assignmentQuestionId, sectionId, subsectionId }) => {
    const updatedSections = state.courseCurriculum.results.map((section) => {
      if (section.id === sectionId) {
        const updatedSubsections = section.subsections.map((subsection) => {
          if (subsection.id === subsectionId && subsection.type === 'assignment') {
            const filteredQuestions =
              subsection.assignment?.questions?.filter((q) => q.id !== assignmentQuestionId) || [];
            return {
              ...subsection,
              assignment: {
                ...subsection.assignment,
                questions: filteredQuestions,
              },
            };
          }
          return subsection;
        });

        return {
          ...section,
          subsections: updatedSubsections,
        };
      }
      return section;
    });

    return {
      ...state,
      courseCurriculum: {
        ...state.courseCurriculum,
        results: updatedSections as any,
      },
    };
  }),

  // Lerner TODO: enable it if required
  on(CourseActionsV2.loadCourseLearnerSuccess, (state, data) => {
    return {
      ...state,
      courseLerner: data,
    };
  }),

  // Activity handler
  on(CourseActionsV2.createActivityV2, (state, { sectionId }) => {
    return {
      ...state,
      selectedSectionId: sectionId,
      selectedSubsectionId: '',
      selectedActivityId: '',
      activityState: undefined,
      isActivityModalOpen: true,
    };
  }),
  on(CourseActionsV2.editActivityV2, (state, { sectionId, subsectionId, activityId, activityState }) => {
    return {
      ...state,
      selectedSectionId: sectionId,
      selectedSubsectionId: subsectionId,
      selectedActivityId: activityId,
      activityState,
      isActivityModalOpen: true,
    };
  }),
  on(CourseActionsV2.closeActivityV2, (state) => {
    return {
      ...state,
      selectedSectionId: '',
      selectedSubsectionId: '',
      selectedActivityId: '',
      activityState: undefined,
      isActivityModalOpen: false,
    };
  }),
  on(CourseActionsV2.modalNextV2, (state, { activityState }) => {
    return {
      ...state,
      activityState,
    };
  }),
  on(CourseActionsV2.reloadModalV2, (state, { activityState }) => {
    return {
      ...state,
      activityState: activityState === 'type_lecture' ? undefined : activityState,
    };
  }),

  // File upload
  on(CourseActionsV2.uploadStartV2, (state, { referenceId, fileName, fileType, status }) => {
    return {
      ...state,
      uploads: {
        ...state.uploads,
        [referenceId]: {
          referenceId,
          fileName,
          fileType,
          status,
        } as UploadStateV2,
      },
    };
  }),
  on(CourseActionsV2.uploadProgressV2, (state, { referenceId, bytesTotal, bytesUploaded, progressPercent, status }) => {
    if (!state.uploads[referenceId]) {
      return state;
    }
    return {
      ...state,
      uploads: {
        ...state.uploads,
        [referenceId]: {
          ...state.uploads[referenceId],
          bytesTotal,
          bytesUploaded,
          progressPercent,
          status,
        } as UploadStateV2,
      },
    };
  }),
  on(CourseActionsV2.uploadStatusUpdateV2, (state, { referenceId, status, error }) => {
    const updateStatus = {
      ...state,
      uploads: {
        ...state.uploads,
        [referenceId]: {
          ...state.uploads[referenceId],
          status: status,
          error,
        } as UploadStateV2,
      },
    };
    return updateStatus;
  }),
  on(CourseActionsV2.uploadCompleteV2, CourseActionsV2.uploadAbortV2, (state, { referenceId }) => {
    return {
      ...state,
      uploads: _.omit(state.uploads, referenceId),
    };
  }),

  // Annotations
  on(CourseActionsV2.loadAnnotationsSuccessV2, (state, annotations) => {
    return {
      ...state,
      annotations: annotations,
    };
  })
);

export const courseDetailsV2Feature = createFeature({
  name: 'courseStateV2',
  reducer: courseDetailsV2Reducer,
});
