export interface AnnotationsV2 {
  id: string;
  content_type?: string;
  course?: string;
  model?: string;
  key?: string;
  date_created?: string;
  date_updated?: string;
  object_id?: string;
  field?: string;
  feedback?: string;
  required_action?: boolean;
  is_resolved?: boolean;
}

export interface AnnotationsByProjectResultV2 {
  courses?: [];
  annotations: AnnotationsV2[];
}

// Feedback
export type FeedbackStateV2 = {
  isFeedbackModalOpen: boolean;
  fieldId: string;
  fieldKey: string;
  fieldModel: ContentTypeModelsV2 | undefined;
  fieldValue: string;
};

export enum ContentTypeAppsV2 {
  COURSES = 'courses',
}
export enum ContentTypeModelsV2 {
  COURSE = 'course',
  OBJECTIVE = 'courseobjective',
  LEARNER = 'courselearner',
  REQUIREMENT = 'courserequirement',
  SECTION = 'section',
  SUBSECTION = 'subsection',
  QUIZ_QUESTION = 'quizquestion',
  ASSIGNMENT_QUESTION = 'assignmentquestion',
  LECTURE = 'lecture',
  QUIZ = 'quiz',
  ASSIGNMENT = 'assignment',
}

export type AnnotationDetailV2 = AnnotationsV2 & {
  /** model/table - object_id - field */
  key: string;
  app_label?: ContentTypeAppsV2;
  model?: ContentTypeModelsV2;
};
