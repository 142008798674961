import { Injectable } from '@angular/core';
import { MemoizedSelector, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import {
  API_COURSES_ANNOTATIONS_BY_PROJECT_GET,
  API_COURSES_ANNOTATION_UPSERT,
  Annotation,
  AnnotationDetail,
  ContentTypeModels,
  CopiedEntity,
  CourseV2,
  PartialEntity,
  SubsectionType,
} from '../../models';
import {
  annotationKey,
  selectAnnotationByKey,
  selectAnnotationHistory,
  selectAssignmentDetailByAssignmentIdV2,
  selectAssignmentQuestion,
  selectCourse,
  selectCourseV2,
  selectLearnerV2,
  selectLectureV2,
  selectObjectiveV2,
  selectQuizQuestion,
  selectQuizV2,
  selectRequirementV2,
  selectSection,
  selectSubsection,
} from '../../store';
import { linkedListToArray } from '../../utils';
import { HttpService } from '../http';
import { Logger } from '../logger';

const log = new Logger('AnnotationService');

export type AnnotationsByProjectResult = { courses: CourseV2[]; annotations: Annotation[] };

export const contentTypeModelToSelectorMap: {
  [key in ContentTypeModels]: (id: string, type?: string) => MemoizedSelector<any, any>;
} = {
  [ContentTypeModels.COURSE]: (_: string) => selectCourseV2,
  [ContentTypeModels.SECTION]: (id: string) => selectSection(id),
  [ContentTypeModels.SUBSECTION]: (id: string) => selectSubsection(id),
  [ContentTypeModels.QUIZ]: (id: string) => selectQuizV2(id),
  [ContentTypeModels.LECTURE]: (id: string) => selectLectureV2(id),
  [ContentTypeModels.ASSIGNMENT]: (id: string) => selectAssignmentDetailByAssignmentIdV2(id),
  [ContentTypeModels.ASSIGNMENT_QUESTION]: (id: string) => selectAssignmentQuestion(id),
  [ContentTypeModels.QUIZ_QUESTION]: (id: string) => selectQuizQuestion(id),
  [ContentTypeModels.OBJECTIVE]: (id: string) => selectObjectiveV2(id),
  [ContentTypeModels.LEARNER]: (id: string) => selectLearnerV2(id),
  [ContentTypeModels.REQUIREMENT]: (id: string) => selectRequirementV2(id),
  // [ContentTypeModels.QUIZ]: (id: string) => selectQuiz(id),
  // [ContentTypeModels.LECTURE]: (id: string) => selectLecture(id),
  // [ContentTypeModels.ASSIGNMENT]: (id: string) => selectAssignment(id),
  // [ContentTypeModels.OBJECTIVE]: (id: string) => selectObjective(id),
  // [ContentTypeModels.LEARNER]: (id: string) => selectLearner(id),
  // [ContentTypeModels.REQUIREMENT]: (id: string) => selectRequirement(id),
};

export const subsectionTypeToContentTypeModelMap = {
  [SubsectionType.LECTURE]: ContentTypeModels.LECTURE,
  [SubsectionType.QUIZ]: ContentTypeModels.QUIZ,
  [SubsectionType.ASSIGNMENT]: ContentTypeModels.ASSIGNMENT,
};

type GetAnnotationCountOpts = {
  prevCourseOnly: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AnnotationService {
  constructor(private readonly http: HttpService, private readonly store: Store) {}

  upsertAnnotation(annotation: PartialEntity<Annotation>): Observable<Annotation> {
    return this.http.post<Annotation>(API_COURSES_ANNOTATION_UPSERT, annotation);
  }

  getAnnotationsByProjectId(projectId: string): Observable<AnnotationsByProjectResult> {
    return this.http.get<AnnotationsByProjectResult>(API_COURSES_ANNOTATIONS_BY_PROJECT_GET, { project_id: projectId });
  }

  getAnnotation(fieldKey: string, fieldModel: ContentTypeModels, fieldId: string): Observable<Annotation> {
    const key = annotationKey({
      model: fieldModel,
      field: fieldKey,
      object_id: fieldId,
    });
    return this.store.select(selectAnnotationByKey(key));
  }

  getAnnotationCount(
    fieldKey: string,
    fieldModel: ContentTypeModels,
    fieldId: string,
    opts: GetAnnotationCountOpts = {
      prevCourseOnly: false,
    }
  ): Observable<number> {
    const course$ = this.store.select(selectCourse);
    const annotationHistory$ = this.store.select(selectAnnotationHistory);

    const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
      filter(([, annotationHistory]) => annotationHistory.length > 0),
      map(([course, annotationHistory]) => {
        return annotationHistory.filter(
          (annotation) =>
            !opts.prevCourseOnly ||
            annotation.course === (typeof course.source === 'object' ? course.source?.id : course.source)
        );
      }),
      map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
    );

    const entity$ = this.store
      .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
      .pipe(map((entity) => entity as CopiedEntity<unknown>));

    return combineLatest([entity$, annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        return linkedListToArray(entity)
          .map((entity) => {
            const key = annotationKey({
              model: fieldModel,
              field: fieldKey,
              object_id: entity.id,
            });
            const annotation = annotationHistoryMap[key];
            // log.debug('annotation key', key, annotation);
            // log.debug('annotationHistory', annotationHistory);
            return !!annotation;
          })
          .filter((hasAnnotation) => hasAnnotation).length;
      }),
      distinctUntilChanged()
      // tap((count) => log.debug('annotation count', fieldModel, fieldId, fieldKey, count))
    );
  }

  // annotationChecker(
  //   fieldKey: string,
  //   fieldModel: ContentTypeModels,
  //   fieldId: string,
  //   opts: GetAnnotationCountOpts = {
  //     prevCourseOnly: false,
  //   }
  // ): Observable<object> {
  //   const course$ = this.store.select(selectCourse);
  //   const annotationHistory$ = this.store.select(selectAnnotationHistory);

  //   const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
  //     filter(([, annotationHistory]) => annotationHistory.length > 0),
  //     map(([course, annotationHistory]) =>
  //       annotationHistory.filter(
  //         (annotation) =>
  //           !opts.prevCourseOnly ||
  //           annotation.course === (typeof course.source === 'object' ? course.source?.id : course.source)
  //       )
  //     ),
  //     map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
  //   );

  //   const entity$ = this.store
  //     .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
  //     .pipe(map((entity) => entity as CopiedEntity<unknown>));

  //   return combineLatest([entity$, annotationHistoryMap$]).pipe(
  //     map(([entity, annotationHistoryMap]) => {
  //       return linkedListToArray(entity)
  //         .map((entity) => {
  //           const key = annotationKey({
  //             model: fieldModel,
  //             field: fieldKey,
  //             object_id: entity.id,
  //           });
  //           const annotation = annotationHistoryMap[key];
  //           console.log('🚀 ~ AnnotationService ~ .map ~ annotation:', annotation);
  //           return annotation;
  //         })
  //         .filter((hasAnnotation) => {
  //           console.log('🚀 ~ AnnotationService ~ .filter ~ hasAnnotation:', hasAnnotation);
  //           return hasAnnotation;
  //         });
  //     }),
  //     distinctUntilChanged()
  //   );
  // }

  annotationChecker(
    fieldKey: string,
    fieldModel: ContentTypeModels,
    fieldId: string,
    opts: GetAnnotationCountOpts = {
      prevCourseOnly: false,
    }
  ): Observable<object> {
    const course$ = this.store.select(selectCourseV2);
    const annotationHistory$ = this.store.select(selectAnnotationHistory);

    const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
      filter(([, annotationHistory]) => annotationHistory.length > 0),
      map(([course, annotationHistory]) =>
        annotationHistory.filter((annotation) => !opts.prevCourseOnly || annotation.course === course.source)
      ),
      map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
    );

    // Entity selector
    const entity$ = this.store
      .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
      .pipe(map((entity) => entity as CopiedEntity<unknown>));

    return combineLatest([entity$, annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        if (typeof entity?.source !== 'string') {
          return null;
        }

        const key = annotationKey({
          model: fieldModel,
          field: fieldKey,
          object_id: entity.source,
        });

        const annotation = annotationHistoryMap[key];
        return [annotation];
      }),
      filter((annotation): annotation is PartialEntity<AnnotationDetail>[] => {
        return !!annotation;
      }),
      distinctUntilChanged()
    );
  }

  getAnnotationCountV2(
    fieldKey: string,
    fieldModel: ContentTypeModels,
    fieldId: string,
    opts: GetAnnotationCountOpts = {
      prevCourseOnly: false,
    }
  ): Observable<number | undefined> {
    const course$ = this.store.select(selectCourseV2);
    const annotationHistory$ = this.store.select(selectAnnotationHistory);

    const annotationHistoryMap$ = combineLatest([course$, annotationHistory$]).pipe(
      filter(([, annotationHistory]) => annotationHistory.length > 0),
      map(([course, annotationHistory]) => {
        return annotationHistory.filter((annotation) => !opts.prevCourseOnly || annotation.course === course.source);
      }),
      map((annotationHistory) => _.keyBy(annotationHistory, (annotationHistory) => annotationHistory.key))
    );

    const entity$ = this.store
      .select(contentTypeModelToSelectorMap[fieldModel](fieldId))
      .pipe(map((entity) => entity as CopiedEntity<unknown>));

    return combineLatest([entity$, annotationHistoryMap$]).pipe(
      map(([entity, annotationHistoryMap]) => {
        if (typeof entity?.source !== 'string') {
          return null;
        }

        const key = annotationKey({
          model: fieldModel,
          field: fieldKey,
          object_id: entity.source,
        });

        const annotation = annotationHistoryMap[key];

        if (annotation && typeof annotation === 'object' && 'annotation_count' in annotation) {
          return annotation.annotation_count;
        }

        return 0; // Return 0 if feedback_count does not exist or annotation is not an object
      }),
      map((annotation) => annotation ?? undefined),
      distinctUntilChanged()
    );
  }
}
